import React from 'react'

function Comment({ comment }) {
  return (
    <>
      <span className="chat">{comment}</span>
    </>
  )
}

export default Comment